import * as React from 'react';
import "../css/navigation.css";
import logo from '../img/logo/lgoo.png'
import vector from '../img/logo/vector.svg'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { Grid, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/LanguageOutlined';

import { useNavigate, useParams } from 'react-router-dom';

function BottomNavigator({ menuChange }) {
  const [value, setValue] = React.useState(-1); // Set initial value to -1
  const navigate = useNavigate();
  const {id} = useParams()
  const backCategory = () => {
    navigate('/menu/'+id);
  };
  
  const order = () => {
    navigate('/menu/'+id+'/order');
  };
  
  return ( 
<div style={{backgroundColor:"#FFF" ,bottom:0 ,position:'fixed' , width:'100%',display:'flex',flexDirection: 'column',alignItems:'center',justifyContent:'center' ,maxHeight:110}}>
       <Grid  container spacing={0} justifyContent="center" alignItems="center">
        <Grid item xs={2} style={{ textAlign: "center" }}>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center' ,flexDirection: 'column'}}>
          <MenuBookOutlinedIcon onClick={()=>{backCategory()}} style={{color:'#f0b180'}}/>
     
          </div>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}ß>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center' ,flexDirection: 'column'}}>
          <ShoppingCartIcon  style={{color:'#cdd9c9'}} />
          
          </div>
        </Grid>
        <Grid item xs={2}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
         <img onClick={()=>{backCategory()}} style={{ maxWidth: 20, maxHeight: 20, minWidth: 90, minHeight: 90 }} src={logo} alt="" />
   
         </div>
           
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}ß>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center' ,flexDirection: 'column'}}>
          <LanguageIcon onClick={()=>{menuChange()}} style={{color:'#f0b180'}}/>
          
          </div>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }}ß>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center' ,flexDirection: 'column'}}>
          <img style={{maxWidth:20 ,maxHeight:20,minWidth:20 ,minHeight:20}} src={vector} alt="" />
          
          </div>
        </Grid>
        <Grid item  xs={12}>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center' ,flexDirection: 'column'}}>
        <div style={{minWidth:155,maxWidth:155,minHeight:5,backgroundColor:'#f0b180' ,marginBottom : 20 , borderRadius:100}}></div>
        </div>
        </Grid>
       </Grid>
      
    </div>
  );
}

export default BottomNavigator;