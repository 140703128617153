import Bal from '../img/alergens/Bal.png'
import FISH from '../img/alergens/Balık.png';
import SHELLFISH from '../img/alergens/Deniz Kabukluları.png';
//import MOLLUSK from '../img/alergens/Mollusk.png';
import TREE_NUT from '../img/alergens/Yemiş.png';
import PEANUT from '../img/alergens/Yer Fıstığı.png';
import GLUTEN from '../img/alergens/Gluten.png';
import POULTRY from '../img/alergens/Tüy.png';
//import PORK from '../img/alergens/Pork.png';
import REDMEAT from '../img/alergens/Kırmızı Et.png';
import BOVINE from '../img/alergens/Kırmızı Et.png';
import MILK from '../img/alergens/Süt Ürünleri.png';
import EGG from '../img/alergens/Yumurta.png';
import MUSTARD from '../img/alergens/Hardal.png';
import SOY from '../img/alergens/Soya.png';
import SESAME from '../img/alergens/Susam.png';
import SULFITE from '../img/alergens/Sülfitler.png';
import CELERY from '../img/alergens/Kereviz.png';
//import LUPIN from '../img/alergens/Lupin.png';
//import COCONUT from '../img/alergens/Coconut.png';
const alegenImages = {
    "FISH":FISH,
    "SHELLFISH":SHELLFISH,
    //"MOLLUSK":,
    "TREE_NUT":TREE_NUT,
    "PEANUT":PEANUT,
    "GLUTEN":GLUTEN,
    "POULTRY":POULTRY,
    //"PORK":PORK,
    "REDMEAT":REDMEAT,
    "BOVINE":BOVINE,
    "MILK":MILK,
    "EGG":EGG,
    "MUSTARD":MUSTARD,
    "SOY":SOY,
    "SESAME":SESAME,
    "SULFITE":SULFITE,
    "CELERY":CELERY,
    //"LUPIN":,
   // "COCONUT":,
    'HONEY':Bal,
  }
export default alegenImages