import Banner from '../../components/Banner';
import MenuCategoryLoader from "../../components/MenuCategoryLoader";

function MenuHomePage() {
    return (
        <>
    <Banner></Banner>
    <MenuCategoryLoader></MenuCategoryLoader>
    </>

    );
}

export default MenuHomePage;