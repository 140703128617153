import React, { useState } from 'react';
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import { Checkbox, Divider, FormControlLabel, Grid } from "@mui/material";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';
import alegenImages from './Alergens';
function MenuItemSlider(props) {
  const [checkedItems, setCheckedItems] = useState({});
  const [menu, setMenu] = useState(false)
  const [isOpen, SetOpen] = useState(false)


  const openMenu = () => {
    if (menu) {
      setMenu(false)
    }
    else {
      setMenu(true)
    }
  }
  const handleClose = () => SetOpen(false);
  const openModal = () => {
    SetOpen(true)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: 4,
    bgcolor: 'white',

    boxShadow: 24,
    p: 2,
  };
  const styleTextMain = {
    color: 'black',
    fontWeight: 'bold',
    p: 1,
  }
  const styleText = {
    color: 'black',
    p: 1,
  }
  const handleCheckboxChange = (name, price) => {
    setCheckedItems({
      ...checkedItems,
      [name]: !checkedItems[name],
    });

    // Seçilen verileri parent bileşene iletiyoruz
    const selectedItems = props.Modification.filter(item => checkedItems[item.name]);
    props.onDataSelection(selectedItems);
  };

  return (<>
    {!menu ? <div className="w-full   md:p-2" style={{ boxShadow: '0px -2px 20px rgba(0, 0, 0, 0.1)', height: '110px', borderRadius: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', transition: 'height 0.3s ease-in-out' }}>
      <Grid container>
        <Grid item xs={4} sm={4}>
          <img
            className=' rounded-lg'
            src={props.image}
            onClick={openModal}
            alt=""
            style={{ minWidth: '110px', minHeight: '110px', maxWidth: '110px', maxHeight: '110px', borderRadius: '10px', padding: 3, objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs={7} sm={4} >
          <div className='flex flex-col h-[110px] ' style={{ paddingTop: 7, paddingBottom: 7 }}>
            <div style={{ fontFamily: "'Baloo 2', cursive", color: '#262628', fontSize: '15px', fontWeight: 600 }} >{props.name}</div>
            <div className='text-[11px] text-[#9B9B9B] overflow-hidden line-clamp-3 font-[Roboto] ' style={{ fontWeight: 400 }}>
              {props.description.length > 20 ? `${props.description.slice(0, 60)}...` : props.description}
            </div>
            <div className='mt-auto'>
              <Typography sx={{ color: '#979797', fontSize: '16px', fontWeight: 400 }}>{props.price}₺</Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={1} sm={4} className='flex flex-col'>
          <div className='flex flex-col space-y-16'>
            <div onClick={openMenu}>
              <SearchOutlinedIcon fontSize="small" style={{ color: '#FFA500' }} />
            </div>
            {!props.isAlcoholic && (
              <div onClick={props.onButtonClick}>
                <ShoppingCartRoundedIcon fontSize="small" style={{ color: '#FFA500' }} />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div> : <div className="w-full md:p-2" style={{ boxShadow: '0px -2px 20px rgba(0, 0, 0, 0.1)', height: '550px', borderRadius: '10px', display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', flexDirection: 'column', transition: 'height 0.3s ease-in-out', padding: 10 }}>
      <div>
        <div>
          <img
            className=' rounded-lg'
            src={props.image}
            onClick={openModal}
            alt=""
            style={{ minWidth: '300px', minHeight: '300px', maxWidth: '300px', maxHeight: '300px', borderRadius: '7px', objectFit: 'cover', padding: 4 }}
          />
          <div onClick={openMenu} style={{ position: 'absolute', right: 0, top: 0 }}>
            <CloseIcon fontSize="small" style={{ color: '#FFA500' }} />
          </div>
        </div>
        <div className='flex flex-col  ' style={{ padding: 10 }}>
          <div style={{ fontFamily: "'Baloo 2', cursive", color: '#262628', fontSize: '15px', fontWeight: 600 }} >{props.name}</div>
          <div  className='text-[11px] pt-2 pb-5 text-[#9B9B9B] overflow-hidden line-clamp-3 font-[Roboto] ' style={{ fontWeight: 400 }}>
            {props.description.length > 20 ? `${props.description.slice(0, 60)}...` : props.description}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' ,marginTop:10}}>
            {props.includesAllergen ? props.includesAllergen.map((alergen, index) => (
              <div key={index}>
                <img src={alegenImages[alergen]} alt={alergen} style={{ width: 40 ,height:20 }} />
              </div>
            )) : <></>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' ,marginTop:5}}>
          <table>
            {props.Modification ? props.Modification.map((modification, index) => (
             <FormControlLabel
             key={index}
             control={
               <Checkbox
                 checked={checkedItems[modification.name] || false}
                 onChange={() => handleCheckboxChange(modification.name, modification.price)}
               />
             }
             label={`${modification.name} - ${modification.price} TL`}
           />
            )) : <></>}
          </table>
          </div>
          <div  style={{ display: 'flex', justifyContent: 'space-between' ,marginTop:20}}>
            <Typography sx={{ color: '#979797', fontSize: '16px', fontWeight: 400 }}>{props.price}₺</Typography>
            {!props.isAlcoholic && (
              <div onClick={props.onButtonClick}>
                <ShoppingCartRoundedIcon fontSize="medium" style={{ color: '#FFA500' }} />
              </div>
            )}
          </div>
        </div>
      </div>

    </div>}


    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <img
          style={{ maxWidth: 350, maxHeight: 350, minWidth: 350, minHeight: 350, display: 'block', margin: 'auto', borderRadius: '5%', marginBottom: 10 }}
          className="ali"
          src={props.image}
          alt=""
        />

        <div style={{ margin: 'auto', marginBottom: 10 }}>
          <Typography sx={{ fontFamily: 'Baloo 2', color: '#262628', fontSize: '15px', fontWeight: 600 }}>{props.name}</Typography>
          <div className='text-[11px] text-[#9B9B9B] overflow-hidden line-clamp-3 font-[Montserrat]'>{props.description}</div>
          <Typography sx={{ color: '#979797', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 400 }}>{props.price}₺</Typography>
        </div>

        {!props.isAlcoholic ? <div style={{ display: 'flex', justifyContent: 'flex-end' }}><div style={{ width: 10, height: 10 }} onClick={props.onButtonClick} onMouseEnter={() => { document.getElementById('shopping-cart-icon').style.color = 'red'; }} onMouseLeave={() => { document.getElementById('shopping-cart-icon').style.color = '#FFA500'; }}><ShoppingCartRoundedIcon id="shopping-cart-icon" fontSize="small" style={{ color: '#FFA500' }} /></div></div> : <> </>}<br />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={{ minWidth: 155, maxWidth: 155, minHeight: 5, backgroundColor: '#FFA500', marginBottom: 20, borderRadius: 100 }}></div>
        </div>
      </Box>
    </Modal>
  </>
  );
}

export default MenuItemSlider;