import { Divider } from "@mui/material";
import logo from '../img/logo/gelik_beyazlogo.svg'
import { useLocation, useNavigate } from "react-router-dom";
 
function Banner() {
    const location = useLocation();
    const navigate = useNavigate()
    const onlineHomePage = '/online';
    const menuHomePage = '/menu';
    const home = ()=>{
      const homePage = location.pathname.startsWith('/online') ? onlineHomePage : menuHomePage;
      navigate(homePage);
    }
    return (
        <div className="w-full  min-h-[100px] text-center items-center">
        <div className="text-white min-h-[100px]  bg-mycolor grid place-items-center "><img  className="max-w-[200px] text-center m-4" src={logo} alt=""  /></div>
        <Divider variant=""></Divider>
        </div> 
      );
}

export default Banner;