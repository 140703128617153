import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CategoryPage from './pages/CategoryPage';
import OrderPage from './pages/OrderPage';
import ThemeContext from './components/ThemeContext';
import MenuCategoryPage from './pages/menu/MenuCategoryPage';
import MenuOrderPage from './pages/menu/MenuOrderPage';
import MenuHomePage from './pages/menu/MenuHomePage';
import { useState } from 'react';
 
function App() {
  const [orderData, setOrderData] = useState([]);

  return (
    <ThemeContext.Provider value={{ orderData, setOrderData }}> 
      <Routes>
        <Route path="/" element={<Navigate to="/menu" />} />
        <Route path="/menu/*" element={<MenuRoutes />} />
        <Route path="/online/*" element={<OnlineRoutes />} />
      </Routes>
    </ThemeContext.Provider>
  );
}

function MenuRoutes() {
  return (
    <Routes>
      <Route index element={<MenuHomePage />} />
      <Route path="/category/:idCategory" element={<MenuCategoryPage />} />
    </Routes>
  );
}

function OnlineRoutes() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/category/:idCategory" element={<CategoryPage />} />
      <Route path="/order" element={<OrderPage />} />
    </Routes>
  );
}
 
export default App;