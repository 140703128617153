import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../App.css'
import { Typography } from '@mui/material';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}
function CategoryLoader() {
  const [browserLanguage, setBrowserLanguage] = React.useState('');
  const  establishmentId =process.env.REACT_APP_ESTABLISMENT
  const navigate = useNavigate();
    const test =(id)=>{
        navigate('/online/category/'+id)
    }
    const language = async () =>{
      try{
        const userLanguage =  navigator.language || navigator.userLanguage;
        await setBrowserLanguage(userLanguage);
      }catch(error){

      }
    
      fetchData();
    }
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_NGROK+'/menu/guest/'+establishmentId, {
          params: {
            menuType:'DELIVERY',
            locale: browserLanguage,
          },
          headers:{
           
          },
        });
        setItemData(response.data.Categories);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    React.useEffect(() => {
      language()
      
    },[]);

  const [itemData,setItemData] = React.useState([])
  
  return (
    <div style={{  margin: '5px 5px'}}>
<ImageList sx={{width: '100%', margin:'auto'}} variant="quilted" cols={4} gap={3}>
  {!itemData.length == 0 ? (
    itemData.map((item, index) => (
      <ImageListItem
      
        key={item.id}
        cols={4}
        rows={2}
        sx={{ width: '100%',  margin: 'auto', maxWidth: '800px', minWidth: '250px',alignItems:'center'}}
        
      >
     <div className="image-container" style={{borderRadius:'15px'}} onClick={() => test(item.id)}>
    <img
      onClick={() => test(item.id)}
      {...srcset(item.image, 121, (item.rows || 1), (item.cols || 4))}
      alt={item.name}
      loading="eager"
      style={{
        top: 0,
        left: 0,
        objectFit:'cover',
        borderRadius: '10px',
        width: '100%',
        maxHeight: 230,
        maxWidth: '800px', // Büyük ekranlar için maksimum genişlik
        minWidth: '250px',
      }}
    />
  </div>
       
        <ImageListItemBar sx={{ borderBottomLeftRadius: '50px', borderBottomRightRadius: '10px',background:'none' }} title={<span style={{ fontSize: '25px', fontFamily: 'Baloo 2', color: '#FFF', textAlign: 'left', fontWeight: 600, fontStyle: 'normal' }}>{item.name}</span>} />
      </ImageListItem>
    ))
  ) : (
    <Typography sx={{ alignItems: 'center' }}>Kategori Bulunamadı</Typography>
  )}
</ImageList>
  </div>
  );
}

export default CategoryLoader;