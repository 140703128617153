import Banner from "../components/Banner";
import CategoryLoader from "../components/CategoryLoader";

function HomePage() {
    return (
        <>
    <Banner></Banner>
    <CategoryLoader></CategoryLoader>
    </>

    );
}

export default HomePage;