
import { Box, Button, Divider, Grid, List, ListItem, TextField, Typography, Modal, Select, InputLabel, MenuItem } from "@mui/material";
import Banner from "../components/Banner";
import BottomNavigator from "../components/BottomNavigator";
import ThemeContext from '../components/ThemeContext';
import React, { useContext, useEffect, useState } from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import axios from "axios";
import ClearIcon from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
function OrderPage() {
  const [isOpen, SetOpen] = useState(false)
  const [orderType, setOrderType] = React.useState('');
  const [deliveryInstructionss, setDeliveryInstructions] = useState('')
  const [dist, setDist] = useState('')
  const [numbers, setNumbers] = useState('')
  const [orderDescription, setOrderDescription] = useState('')
  const [adress, setAdress] = React.useState('');
  const handleChange = (event) => {
    setOrderType(event.target.value);
  };
  const adressChange = (event) => {
    setAdress(event.target.value);
  };
  const setDists = (event) => {
    setDist(event.target.value);
  };
  const orderDescriptionChange = (event) => {
    setOrderDescription(event.target.value);
  };
  const deliveryInstructions = (event) => {
    setDeliveryInstructions(event.target.value);
  };
  const numberss = (event) => {
    setNumbers(event.target.value);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '280px',
    bgcolor: 'white',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: 5
  };

  const handleClose = () => SetOpen(false);
  const openModal = () => {
    SetOpen(true)
  }
  const [totalPrice, setTotalPrice] = useState(0);
  const { orderData, setOrderData } = useContext(ThemeContext)
  console.log(orderData)
  const hesaplar = () => {
    let totalPrice = 0;
    if (orderData.length !== 0) {
      for (let i = 0; i < orderData.length; i++) {
        totalPrice += orderData[i].price * orderData[i].quantity;
      
        if (orderData[i].selectedModification && orderData[i].selectedModification.length > 0) {
          for (let j = 0; j < orderData[i].selectedModification.length; j++) {
            totalPrice += orderData[i].selectedModification[j].price * orderData[i].quantity;
          }
        }
      }
    }
    return totalPrice;
  };
  const checkOut = () => {
    openModal();
    const totalPrice = hesaplar(); // hesaplar fonksiyonunu çağırarak totalPrice'ı hesaplıyoruz.
    setTotalPrice(totalPrice); // totalPrice'ı güncelliyoruz.
  };
  const deleteOrder = (index) => {
    const updatedOrderData = [...orderData];
    if (updatedOrderData[index].quantity > 1) {
      updatedOrderData[index].quantity--;
    } else {
      updatedOrderData.splice(index, 1);
    }
    setOrderData(updatedOrderData);

    // Eğer orderData dizisi boşsa, totalPrice'ı 0'a eşitleyerek güncelliyoruz.
    const updatedTotalPrice = hesaplar();
    setTotalPrice(updatedTotalPrice);
  };
  const updateOrder = (index) => {
    const updatedOrderData = [...orderData];
    updatedOrderData[index].quantity++;
    setOrderData(updatedOrderData);

    // Eğer orderData dizisi boşsa, totalPrice'ı 0'a eşitleyerek güncelliyoruz.
    const updatedTotalPrice = hesaplar();
    setTotalPrice(updatedTotalPrice);
  };
  const orderSend = () => {
    let formData = {}
    let currentDateTime = new Date()
    const order = orderData.map(item => ({ id: item.id, quantity: item.quantity }));
    formData.order = order
    formData.address = adress + dist
    formData.tableId = process.env.REACT_APP_TABLEID
    formData.createdAt = currentDateTime
    formData.establishmentId = process.env.REACT_APP_ESTABLISMENT
    formData.deliveryInstructions = deliveryInstructionss
    formData.kitchenInstructions = orderDescription
    formData.paymentType = orderType
    formData.phoneNumber = numbers

    axios.post(process.env.REACT_APP_NGROK + '/order', formData, {
      headers: {

      },
    }).then((res) => {

    }).catch((error) => {

    }).finally(() => {
      setOrderType('')
      setDeliveryInstructions('')
      setNumbers('')
      setAdress('')
      setOrderData([])
      setTotalPrice()
      handleClose()
    })


  }
  useEffect(() => {
    const total = hesaplar();
    setTotalPrice(total);
  }, [orderData])

  return (
    <>
      <Banner></Banner>
      <div className=" overflow-auto  h-[650px]  p-3">
        <List container spacing={1} className=" h-[600px] min-w-[200px] overflow-auto">
          {orderData.map((item, index) => (
            <ListItem item key={index} style={{ backgroundColor: 'white' }}>
              <div className="w-full">
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item xs={8} className=''>
                    <div style={{ fontFamily: "'Baloo 2', cursive", color: '#262628', fontSize: '15px', fontWeight: 600 }} >{item.name}</div>
                    <Typography className="text-[13px] text-[#9B9B9B] overflow-hidden line-clamp-3 font-[Roboto]">{item.description}</Typography>
                    <Typography sx={{ color: '#979797', fontSize: '13px', fontWeight: 400 }}>{item.price}₺</Typography>
                    {item.selectedModification && item.selectedModification.length > 0 ? (
                      item.selectedModification.map((modification, index) => (
                        <div style={{color: '#979797', fontSize: '13px', fontWeight: 400}} key={index}>{modification.name}</div>
                      ))
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid justifyContent='flex-end' alignItems='flex-end' item xs={4} className=' items-center justify-end'>

                    <div style={{ display: 'flex' }}>
                      <ClearIcon style={{ color: '#FFA500', margin: 5 }} onClick={() => deleteOrder(index)} />
                      <Typography style={{ margin: 5 }}>{item.quantity}</Typography>
                      <Add style={{ color: '#FFA500', margin: 5 }} onClick={() => updateOrder(index)} />
                    </div>




                  </Grid>
                </Grid>
                <Divider sx={{ bgcolor: '#F7FFE5' }} className='mt-1 mb-1'></Divider>


              </div>
            </ListItem>
          ))}
        </List>

      </div>
      <div onClick={checkOut} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', backgroundColor: '#458f87', padding: 10 }}>
        <div style={{ flex: 1, fontSize: 12, color: 'white', textAlign: 'center' }}>{orderData.length} Adet  Ürün</div>
        <div style={{ textAlign: 'right' }}>
          <div style={{ color: 'white', fontSize: 20, fontWeight: 500 }} >
            Sipariş
          </div>
        </div>
        <Typography style={{ color: 'white', fontSize: 12 }} className='text-center text-xl font-bold flex-1'>
          {totalPrice}₺
        </Typography>
      </div>
      <BottomNavigator></BottomNavigator>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <div style={{ width: 200 }}>
            <InputLabel id="demo-simple-select-label">Ödeme Şekli</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderType}
              label="Age"
              sx={{ mb: 2, minWidth: 120, mr: 1, maxHeight: 50 }}
              onChange={handleChange}
            >
              <MenuItem value={'CARD'}>Nakit</MenuItem>
              <MenuItem value={'CASH'}>Kredi Kartı</MenuItem>
            </Select>
          </div>
          <TextField id="outlined-multiline-static"
            fullWidth
            label="İlçe"
            value={dist}
            sx={{ mb: 2 }}
            onChange={setDists}
            variant="outlined"></TextField>
          <TextField id="outlined-multiline-static"
            fullWidth
            label="Adress"
            multiline
            rows={2}
            value={adress}
            sx={{ mb: 2 }}
            onChange={adressChange}
            variant="outlined">

          </TextField>
          <TextField id="outlined-multiline-static"
            fullWidth
            label="Ürünler  hakkında bir notunuz varmı"
            multiline
            rows={2}
            value={orderDescription}
            sx={{ mb: 2 }}
            onChange={orderDescriptionChange}
            variant="outlined">

          </TextField>
          <TextField id="outlined-multiline-static"
            fullWidth
            label="Not"
            multiline
            rows={2}
            value={deliveryInstructionss}
            sx={{ mb: 2 }}
            onChange={deliveryInstructions}
            variant="outlined">

          </TextField>
          <TextField id="outlined-multiline-static"
            fullWidth
            type="number"
            label="Telefon Numarası"
            multiline
            rows={1}
            value={numbers}
            sx={{ mb: 2 }}
            onChange={numberss}
            variant="outlined">

          </TextField>
          <Button fullWidth onClick={orderSend}  ><ShoppingCartIcon style={{ color: '#458f87' }} /> <p style={{ color: '#458f87' }}>Sipariş Ver</p></Button>
        </Box>

      </Modal>
    </>

  );
}

export default OrderPage;