
import {  useParams } from "react-router-dom";
import Banner from "../../components/Banner";
import MenuItemSlider from '../../components/MenuItemSlider'
import MenuBottomNavigation from "../../components/MenuBottomNavigator";
import {  Divider,ListItem ,List} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import ThemeContext from "../../components/ThemeContext";
function CategoryPage() {
    const { idCategory } = useParams();
    const [browserLanguage, setBrowserLanguage] = useState('');
    const {orderData,setOrderData} =useContext(ThemeContext)
    const  establishmentId =process.env.REACT_APP_ESTABLISMENT
    const [isEng,setIsEng]=useState(false)
    const [menuName,setMenuName]=useState('')
    const [menuData, setMenuData] = useState([]);
    const [lanCode, setLancode] = useState(false);
    const [selectedModification, setSelectedModification] = useState([]);

    const handleDataSelection = (selectedItems) => {
      setSelectedModification(selectedItems);
    };
  
    const language = async () =>{
      try{
        const userLanguage = await navigator.language || navigator.userLanguage;
        await setBrowserLanguage(userLanguage);
        if(userLanguage==='en_US'){
          setIsEng(true)
        }
      }catch(error){

      }
    
      fetchData();
    }
    useEffect(() => {
      language();
    }, []);
    const fetchData = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_NGROK+'/category/guest/'+establishmentId+'/category/'+idCategory ,{
            headers:{
              
  
            },
            params: {
                menuType: 'TABLE',
                locale: browserLanguage,
              }
          });
          setMenuData(response.data.MenuItems)
          setMenuName(response.data.name)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    const takeOrder =(items)=>{
        let quantity = 1
        items.quantity= quantity
        items.selectedModification=selectedModification
        function arraysAreEqual(arr1, arr2) {
          if (arr1.length !== arr2.length) {
            return false;
          }
          
          for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
              return false;
            }
          }
          
          return true;
        }
        const existingItem =  orderData.find(item => item.id === items.id && arraysAreEqual(item.selectedModification, items.selectedModification));;
        const updatedOrderData = [...orderData, items];
        if (existingItem) {
            const updatedOrderData = orderData.map(item =>
              item.id === existingItem.id ? { ...item, quantity: item.quantity + 1 } : item
            ); 
            setOrderData(updatedOrderData);
          } 
        else{
         const updatedOrderData = [...orderData, items];
           setOrderData(updatedOrderData);
        } 

    }
    const lanChange = async ()=>{
      if(!isEng){
        try {
          const response = await axios.get(process.env.REACT_APP_NGROK+'/category/guest/'+establishmentId+'/category/'+idCategory ,{
            headers:{
              
  
            },
            params: {
              menuType: 'TABLE',
              locale: 'en_US',
               
              }
          });
          setMenuData(response.data.MenuItems)
          setMenuName(response.data.name)
        } catch (error) {
          console.error("Error fetching data:", error);
        }

      }
      else{
        try {
          const response = await axios.get(process.env.REACT_APP_NGROK+'/category/guest/'+establishmentId+'/category/'+idCategory ,{
            headers:{
              
  
            },
            params: {
              menuType: 'DELIVERY',
               
              }
          });
          setMenuData(response.data.MenuItems)
          setMenuName(response.data.name)
        } catch (error) {
          console.error("Error fetching data:", error);
        }


      }
    }
    return (<div className="">
        <Banner ></Banner>

        <div className="w-full mb-3 mt-3 text-lg">
        <div className="text-center  text-2xl pb-3 font-semibold text-[#458f87] shadow-md" style={{fontFamily: "'Baloo 2', cursive"}}> {menuName}</div>
            <Divider variant=""></Divider>
            <Divider variant=""></Divider>
        </div>
        <List container spacing={1} className=" h-[600px] min-w-[200px] overflow-auto">
            {!menuData.lenght ? menuData.map((item,index) => (
                <ListItem item  key={index} >
                    <MenuItemSlider  onDataSelection={handleDataSelection}  onButtonClick={() => takeOrder(item)} takeOrder={takeOrder} lanCode={lanCode} key={item.id} {...item} description={item.description}></MenuItemSlider>
                </ListItem>
            )):<div style={{textAlign:'center'}}>Kategoriye ait ürün bulunamadı</div>}
        </List>
        <MenuBottomNavigation menuChange={lanChange}></MenuBottomNavigation>
    </div>);
}

export default CategoryPage;